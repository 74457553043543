import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import Grid from '../components/elements/grid'
import Icon from "../components/elements/icon"
import Layout from '../layouts'
import { SizedText, Canon, Body } from '../components/elements/typography'
import LargeHero from '../components/elements/largeHero'

import rightArrowIcon from "../theme/icons/right-arrow.svg"
import linkIcon from "../theme/icons/link.svg"
import ReviewSection from '../components/sections/reviewSection'

const LandingPage = () => {
  return (
    <Layout isSubMenuOpen={false}>

      <LargeHero.Container
        background={{
          //https://www.svgeez.com/projects/mod-dots-repeating-background-svg
          backgroundColor:`#f2c056`,
          backgroundImage:`url("/images/landing-hero.jpg")`,
          backgroundPosition: `right`,
          backgroundSize: `contain`,
          backgroundRepeat: `no-repeat`,
          padding: `12rem 0`
        }}
      >
        <LargeHero.Content>
          <h1 css={{fontFamily: `Proxima-nova`, textTransform: `uppercase`, 'span': {display: `block`, lineHeight: `1`, padding: `0.25rem 0`}}}>
            
            <SizedText as="span" smallSize="2.887" largeSize="7.406">New Website</SizedText>
            <SizedText as="span" smallSize="1.602" largeSize="3.247">
              Launch Countdown<br />
              Timer Commenced
            </SizedText>          
          </h1>
          <p css={{display: `block`, fontFamily: `source-sans-pro`, fontWeight: `300`, textTransform: `capitalize`, fontSize: `2.027rem`, paddingTop: `1rem`}}>Learn all about the new changes below.</p>
        </LargeHero.Content>
        <LargeHero.Image>

        </LargeHero.Image>
      </LargeHero.Container>

      <Grid.FullWidthSection>
        <Grid.Block start={['6']} span={['6']}>
          <Canon>What to Know</Canon>
        </Grid.Block>
      </Grid.FullWidthSection>

    </Layout>
  )
}

export default LandingPage


